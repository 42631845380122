.nav-link {
  color: #916c95;
  font-size: 18px;
  font-weight: 600;
  padding: 0.65rem 1rem !important;
}

.nav-link:hover,
.navbar-nav .nav-link.show {
  color: #482d8d;
}
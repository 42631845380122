#home_container {
  margin-top: -140px;
}

.briefing {
  background-image: linear-gradient(to bottom, #e5e3e8, rgba(229, 227, 232, 0));
  background-repeat: no-repeat;
}
.briefing-title {
  font-size: 41px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
.briefing-text {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin-top: 1rem;
}

.categories {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.section-title {
  font-size: 40px;
  font-weight: 200;
}
.anchor {
  display: flex;
  align-items: center;
}

.card {
  border: transparent;
  border-radius: 12px;
}

.card-body {
  padding: 2.25rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
  border: 0;
}

.card-body-category {
  margin-right: 2rem;
  border: 2px solid #482d8d;
  background-color: white;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
}

.card-restaurant-right {
  margin: 2rem 0;
}

.card-restaurant-left {
  align-content: end;
}

.toolbar-home {
  padding-bottom: 160px;
  display: flex;
  align-items: center;
  padding-top: 1.25rem;
  max-height: 176.25;
}
.briefing-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
  border: 0;
}
.bienvenida-seccion {
  padding: 1rem 1rem 1rem 5rem;
}
.img-bienvenida {
  max-width: 300px;
  margin: 0px auto;
}

.logo-title-category-card {
  display: flex;
  align-items: center;
}
.logo-category-card {
  height: auto;
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.logo-title-card-restaurant{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.title-category-card {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600 !important;
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 8px;
}
.text-category-card {
  font-size: 1.2rem;
}

.sistemalogros-container{
  padding: 3rem 4rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
  border: 0;
  margin-bottom: 2.5rem;
}
.description-sistemalogros{
  margin-bottom: 40px;
}
.title-sistemalogros{
  font-weight: lighter;
  margin-bottom: 20px;
}

.btn-logroshome{
  padding: 6px 50px;
  border: 2px solid #482d8d;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  align-content: center;
  display: flex;
  margin: auto;
}

// .restaurant-5 .title-category-card {
//   color: rgb(102, 64, 165);
// }
// .restaurant-6 .title-category-card {
//   color: rgb(176, 147, 179);
// }
// .restaurant-7 .title-category-card {
//   color: rgb(171, 166, 162);
// }
// .restaurant-8 .title-category-card {
//   color: rgb(190, 16, 52);
// }
// .restaurant-103 .title-category-card {
//   color: rgb(190, 16, 52);
// }
// .restaurant-104 .title-category-card {
//   color: rgb(94, 59, 159);
// }
// .restaurant-155 .title-category-card {
//   color: rgb(171, 166, 162);
// }

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.55rem + 2px);
  width: calc(1.5em + 1.55rem + 2px);
}
.date-calendar {
  color: var(--dark-laveander, #644968);
  font-size: 20px;
}
.fondoVioleta {
  background-color: #ddd4f1;
  border-radius: 6px 6px 0 0;
}
.description {
  font-size: 18px;
  line-height: 24px;
}
.body-calendar {
  overflow-x: hidden;
  text-align: center;
  margin-bottom: 1.25rem;
  display: grid;
  flex: 1 1 auto;
}

.sub-card-agenda {
  border-radius: 12px;
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}

.customheight[data-v-9bf084bd] {
  height: 605px;
}

.img-contacto {
  width: 206px;
  margin-top: 40px;
  margin-bottom: 12px;
}

//PRIMARY
$brand-primary: #043572;
//SECUNDARY
$brand-secundary: #916a97;
//color azul celeste
$brand-third: #e2f0fa;
//blanco snow
$brand-white: #edeef0;
//VIOLET
$brand-violet: #482D8D;
//BURGUNDY
$brand-burgundy: #ac1d37;
//LAVENDER
$brand-lavender: #906b95;
//STONE
$brand-stone: #8d8379;
//GREY
$brand-grey: #e0ecec;
// silver
$brand-silver: #999999;
// light green
$brand-light-green: #e0ecec;
//texto-muted
$brand-text-muted: #a1a5b7;
// links que no tienen el mismo azul
$brand-hiperlink: #313ed2;
$gray: #4a4a4a;
$gray-lighter: #eee;

//FONTS
$font-family-sans-serif: "Roboto", sans-serif !default;
$font-family: "Roboto", sans-serif;
$body-bg: $gray-lighter;
$text-color: $gray;
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 15%);
$link-hover-secundary: darken($brand-light-green, 15%);
$link-hover-color_light: lighten($brand-primary, 15%);
$link-hover-decoration: none;

// media queries
$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1100px)";
$desktop: "(min-width: 1101px)";

.brand-primary {
  color: $brand-primary;
}

.brand-secundary {
  color: $brand-secundary;
}

.bg-primary {
  background: $brand-primary;
}

.bg-secundary {
  background: $brand-secundary;
}

#dropdown-actions {
  background-color: #f9f9f9;
  color: #b5b5c3;
  border: none;
}
#dropdown-actions:hover {
  color: #7239ea;
}

.dropdown-toggle::after {
  content: none;
  padding: 0;
}

.dropdown-3dots {
  border-radius: 0.475rem;
  background-color: #fff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-color: transparent;
}

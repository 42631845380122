.category-primary {
  background-color: #c6a7e6;
}
.category-secondary {
  background-color: #916a97;
}

.container-xxl {
  max-width: 1320px;
  width: 100%;
  padding: 0 30px;
  border-radius: 16px;
}
.bg-secundary {
  background-color: #c6a7e6;
}

footer.ant-layout-footer {
  padding: 0;
}

.heart-btn {
  position: relative;
  background-color: transparent;
  border: 0;
}
.heart:active {
  fill: #ee3529;
}
.heart {
  width: 20px;
  fill: gray;
  transition: fill 0.5s, transform 0.5s;
  pointer-events: none;
}

.btn-main {
  color: #482d8d;
  background-color: #fdedff;
  padding: 0.825rem 1.75rem;
  border: 0;
  box-shadow: none;
  font-size: 1.15rem;
  font-weight: 700;
  border-radius: 0.625rem;
  width: 100%;
}
.btn-main:hover {
  color: #482d8d;
  background-color: #fdedff;
}

.btn-secondary {
  color: white;
  border: 0;
  background-color: #916c95;
}
.btn-secondary:hover {
  background-color: #916c95;
}

.btn-footer {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 458px;
  margin: auto;
}
.menu-item {
  display: block;
  padding: 0.15rem 0;
}

.menu-link {
  font-size: 16px;
  line-height: 20px;
  color: silver;
  font-weight: 600;
  border-radius: 0.475rem;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.menu-link:hover {
  background-color: #f1faff !important;
}
.dropdown-item,
.acciones {
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: silver;
  background-color: transparent;
}

.menu-item .menu-content {
  padding: 0.65rem 1rem 0 1rem;
  margin-bottom: 4px;
}

.text-muted {
  font-size: 1.25rem;
}

.img-moc-breadcrumb {
  width: 80px;
}

.text-white {
  color: white;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #e1e3ea;
  appearance: none;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-transparent {
  background-color: transparent;
}

//otras variables
.h6 {
  font-size: 1.075rem;
}

.background-container {
  background-color: white;
}
img {
  vertical-align: middle;
}
a {
  text-decoration: none;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.anchor {
  display: flex;
  align-items: center;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-fluid-2 {
  max-width: 80%;
  height: auto;
}
.btn {
  outline: none;
}

.opacity-50,
.opacity-50-hover:hover {
  opacity: 0.5;
}

.botonPrivacidad,
.botonPrivacidad:hover {
  color: #c6a7e6;
}

.h-100 {
  height: 100%;
}

.p-0 {
  padding: 0;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 2rem;
}
.p-10 {
  padding: 2.5rem;
}

.p-lg-10 {
  padding: 2.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-6 {
  padding-bottom: 2rem;
}
.pb-lg-0 {
  padding-bottom: 0;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}
.px-10 {
  padding-right: 2.25rem;
  padding-left: 2.25rem;
}

.pe-2 {
  padding-right: 0.5rem;
}
.pe-8 {
  padding-right: 2rem;
}
.ps-3 {
  padding-left: 0.75rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-5 {
  margin: 1.25rem;
}

.mb-0 {
  margin-bottom: 5px;
}
.mb-1 {
  margin-bottom: 18px;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-11 {
  margin-bottom: 3rem;
}
.mb-16 {
  margin-bottom: 6.5rem;
}
.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 2.5rem;
}

.mb-md-5 {
  margin-bottom: 1.25rem !important;
}

.me-lg-5 {
  margin-right: 1.25rem !important;
}
.ms-lg-5 {
  margin-left: 1.25rem !important;
}

.ml-1 {
  margin-left: 4px;
}
.me-3 {
  margin-right: 1rem;
}
.me-5 {
  margin-right: 1.25rem !important;
}
.me-6 {
  margin-right: 1.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-4 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.25rem;
}
.mt-5 {
  margin-top: 3rem;
}
.mt-6 {
  margin-top: 0.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-auto {
  margin-top: auto;
}
.mt-11 {
  margin-top: 5rem;
}
.fs-1 {
  font-size: 1rem;
}
.fs-2 {
  font-size: 1.1rem !important;
}
.fs-3 {
  font-size: 1.2rem !important;
}
.fs-4 {
  font-size: 1.5rem;
}
.fs-6 {
  font-size: 1.075rem;
}
.fs-7 {
  font-size: 0.95rem;
}
.fs-base {
  font-size: 1rem;
}
.fs-14 {
  font-size: 14px;
}

.fw-semibold {
  font-weight: 500;
}

.d-flex {
  display: flex;
}
.d-inline {
  display: inline;
}
.d-grid {
  display: grid;
}
.col-lg-9 {
  flex: 0 0 auto;
  width: 75%;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mx-3 {
  margin-right: 1em;
  margin-left: 1rem;
}
.mx-5 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}
.mx-10 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.w-200px {
  width: 200px;
}
.w-300 {
  width: 300px;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-gray-400 {
  color: #b5b5c3;
}
.text-gray-500 {
  color: #a1a5b7;
}
.text-gray-700 {
  color: #5e6278;
}
.text-gray-900 {
  color: #181c32;
}
.text-silver {
  color: #999;
}

.fs-2 {
  font-size: 1.5rem;
}
.fw-bold {
  font-weight: 600;
}

.ms-2 {
  margin-left: 0.5rem;
}
.me-1 {
  margin-right: 0.25rem;
}

.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}

.customheight {
  height: 605px;
}

.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-10 {
  gap: 2.5rem;
}

.flex-right {
  display: flex;
  justify-content: right;
}
.flex-end {
  justify-content: end;
  align-items: end;
}
.flex-column {
  flex-direction: column;
}
.flex-column-fluid {
  flex: 1 0 auto !important;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-stack {
  justify-content: end;
  align-items: center;
}

.end-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.position-absolute {
  position: absolute !important;
}

.arrow-breadcrumb {
  width: 9.75px;
}
.breadcrumb-title {
  // font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.breadcrumb-item {
  padding-left: 0.5rem;
}

.text-lg-end {
  text-align: right;
}
.text-lg-start {
  text-align: left;
}

.text-violet {
  color: #482d8d;
}
.bg-violet {
  background-color: #482d8d;
}

.text-lightlavander {
  color: #fdedff;
}
.bg-lightlavander {
  background-color: #fdedff;
}

.text-bluenovo {
  color: #001965;
}

.text-lavander {
  color: #916c95;
}
.text-platpostre {
  color: #795696;
}

.dropdown-menu a {
  color: #482d8d;
  font-weight: 600;
  font-size: 16px;
}


.logo_navbar{
  width: 70%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg {
    flex: 1 0 0%;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }
  .w-lg-500px {
    width: 500px;
  }
  .w-lg-400px {
    width: 400px;
  }
}

.customheight-medical-contact{
  height: 890px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: white !important;
}
.menu-content-profile {
  padding: 0.65rem 1rem;
  align-items: center;
  display: flex;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.name-profile {
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
  display: flex;
}
.badge-lebel-profile {
  color: #50cd89;
  background-color: #e8fff3;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 4px;
}
.mail-profile {
  color: #a1a5b7;
  font-size: 0.95rem;
}

  .websocket-message {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
    display: flex;
    align-items: center;
    background-color: #6332ad;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    max-width: 400px;
    animation: slideIn 0.5s ease-out;
  
    &:hover {
      background-color: #502891;
    }
  
    .achievement-icon {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  
    .message-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      p {
        margin: 0;
        font-size: 1.2em;
  
        &:first-child {
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
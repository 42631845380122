.show-question {
  display: flex;
  border: 3px solid #c9dae1;
  color: #87adbd;
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border-radius: 50px;
  font-family: inherit;
  line-height: 5em;
  user-select: none;
}
.icon-question {
  display: flex;
  align-items: center;
  font-size: 3.75em;
  color: #87adbd;
}
.text-info-changepassword {
  display: block;
  max-height: 200px;
  overflow: auto;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1.5rem;
  color: #3f4254;
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}
.btn-cancel {
  display: inline-block;
  color: #7e8299;
  background-color: #f9f9f9;
  margin: 15px 5px 0;
  border: 0;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}

.btn-send {
  background-color: #9b83d8;
  color: #fff;
  margin: 15px 5px 0;
  border: 0;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}
.modal-footer {
  border-top: 0;
  padding-bottom: 24px;
  justify-content: center;
}
.modal-dialog {
  margin-top: 150px;
}

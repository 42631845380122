.card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  background-color: white;
  border-bottom: 1px solid #f4f4f4;
}

.card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}
.titulo-biografia {
  font-size: 24px;
  color: #644968;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.texto-biografia {
  font-size: 18px;
  padding: 2rem 2.25rem;
}

.achievement-icon {
  max-width: 100px;
}

.todos-logros,
.todos-logros:hover {
  color: #482d8d;
  font-weight: 700;
  font-size: 16px;
}

.img-avatar {
  width: 160px;
}

.verified-icon {
  color: #50cd89;
}
.icon-profile {
  width: 13.55px;
  line-height: 1;
  color: #a1a5b7;
  margin-left: 0.8rem;
  margin-right: 0.4rem;
}
.counter-profile {
  border: 0.75px dashed #e1e3ea;
  min-width: 100px;
  border-radius: 0.475rem;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 0.75rem;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  width: 16px;
}
.svg-help,
.icon-profile {
  color: #a1a5b7;
}

.logro {
  padding: 20px 30px 0 10px;
  margin-bottom: 10px;
  border: 1px solid #482D8D;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.title-inf-logro {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #482D8D;
  font-size: 22px;
}
.text-inf-logro {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #482D8D;
}
.fecha-ĺogro {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}

.img-gray{
  filter: grayscale(100%);
}

.cta-logros {
  display: flex;
  gap: 40px;
}

.button-show-section, .button-show-section:hover {
  color: #ABABAB;
  font-weight: bold;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}

.btn-logros, .btn-logros:hover {
  border-radius: 12px;
  color: #482d8d;
  font-size: 16px;
  padding: 0.65rem;
  width: 350px;
  background-color: #FDEDFF;
  display: block;
  text-align: center;
  font-weight: 700;
}
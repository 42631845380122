.toolbar-contact {
  padding-bottom: 160px;
  background-color: #c8a4da;
  height: 180px;
}

#contact_container {
  margin-top: -150px;
  margin-bottom: 12rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.sub-card-contact {
  background-color: white !important;
  border-radius: 12px;
  padding: 2rem 2.25rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  appearance: none;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::placeholder {
  color: rgba(159, 159, 159, 0.75);
  opacity: 1;
  font-size: 16px;
}

.btn-send {
  background-color: #c6a7e6 !important;
}

.required-advice {
  color: red;
  margin-top: 5px;
}

.botonPrivacidad {
  color: #3085ef;
}

.textfield-contact {
  font-size: 14px;
  margin-left: 4px;
  margin-bottom: 8px;
}

.form-control {
  border: 2px solid #482d8d;
  background-color: transparent !important;
  border-radius: 14px;
}

#agenda_container{
  margin-top: -150px;
}

.img-contactmedic{
  margin-top: 50px;
  margin-bottom: 50px;
  width: 300px;
}
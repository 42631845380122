.card-body-edit {
  padding: 1.25rem 2.25rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  border: 0;
}

.card-footer-edit {
  background-color: white;
  border-top: 1px solid #f4f4f4;
  padding: 2rem 2.25rem;
}

.btn.btn-light.btn-active-light-info {
  color: #a1a5b7;
  background-color: #f8f5ff;
  font-weight: 500;
  border: 0px transparent;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}
.btn.btn-light.btn-active-light-info:hover {
  color: #7239ea;
  background-color: #f8f5ff;
  font-weight: 500;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}

.form-text {
  font-size: 0.875em;
  color: rgba(33, 37, 41, 0.75);
}

.card-body-inf-profile {
  padding: 2rem 2rem 0 2rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
  border: 0;
}

.symbol {
  display: inline-block;
  position: relative;
  border-radius: 0.475rem;
}
.avatar-uploader {
  width: 150px;
  box-shadow: 0 0.5rem 1.5rem 0.5rem #00000013;
  padding: 0.25rem;
  background-color: white;
  border: 1px solid #f4f4f4;
  border-radius: 0.475rem;
  max-width: 100%;
  height: auto;
}

.position-btn-edit {
  position: absolute;
  z-index: 1;
  left: 90%;
}

.btn-edit-avatar {
  z-index: 1;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: white;
  height: 25px;
  width: 25px;
  left: 100%;
}

.btn-edit-avatar:hover {
  color: #7239ea;
}

.text-titulo {
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
}
.text-autor {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}
.text-entrante {
  color: #482d8d;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

.text-principal {
  color: #916c95;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

.text-postre {
  color: #ab0032;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

.text-abstract {
  font-size: 16px;
  color: #5c5c5c;
  font-weight: 400;
}

.video-recurso {
  margin-top: 0.5rem;
  width: 100%;
  border: 1px solid #f4f4f4;
  object-fit: cover;
}
.card-sugerencias {
  min-width: 270px;
  max-width: 290px;
  height: 384px;
  border-radius: 25px;
  border: 2px solid #482d8d;
  background-color: white;
}
.text-tarjeta {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
}

.owl-theme {
  gap: 10px;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 40px;
  span{
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
    left: 14px;
    height: 40px;
    background-color: transparent;
    color: #482D8D;
    font-size: 40px;
  }
}

.owl-prev:hover,
.owl-next:hover {
  background-color: #FDEDFF;
}

.owl-prev {
  left: -65px;
}

.owl-next {
  right: -65px;
}

.btns_infografia {
  display: flex;
  justify-content: center;
  .btn-secondary:hover {
    background-color: rgb(181, 181, 195);
  }
}
.btn_platocontenido {
  height: 52px;
  width: 50%;
  font-size: 20px;
  line-height: 28px;
  padding: 0.7rem 1.5rem;
  margin: 1.5rem;
  font-weight: 500;
}

.pt-74 {
  padding-top: 0.74rem;
}

.img-sugerencias {
  height: 125px;
  width: auto !important;
}

.status-recurso{
  margin-bottom: 10px;
}
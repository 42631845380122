.toolbar-general {
  padding-bottom: 160px;
  height: 229.3px;
}
.toolbar-1 {
  background-color: #6640a5;
}
.toolbar-5 {
  background-color: #ac94f4;
}
.toolbar-6 {
  background-color: #916c95;
}
.toolbar-7 {
  background-color: #482d8d;
}
.toolbar-8 {
  background-color: #c8a4d4;
}
.toolbar-103 {
  background-color: #c8a4d4;
}
.toolbar-104 {
  background-color: #795696;
}
.toolbar-155 {
  background-color: #644b9f;
}
#restaurant_container {
  margin-top: -210px;
}

.video-intro-restaurant {
  height: auto;
  width: 100%;
}
.card-presentation {
  padding: 2.25rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.625rem;
  display: flex;
  background-color: white;
  border: 0;
}

.menudia-description{
  margin-bottom: 3rem;
}

.img-menus {
  height: 170px;
  margin-right: 12px;
}

.title-menudia{
  font-size: 28px;
  margin-bottom: 12px;
  text-align: center;
}

.menu-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
}

.title-entrante {
  color: #482d8d;
}
.title-principal {
  color: #916c95;
}
.title-postre {
  color: #ab0032;
}
.bg-badge-entrante {
  border: 2px solid #482d8d;
  background-color: #F0EAFF;
}
.bg-badge-entrante:hover {
  color: #482d8d;
}
.bg-badge-principal {
  border: 2px solid #916c95;
  background-color: #FDEDFF;
}
.bg-badge-principal:hover {
  color: #916c95;
}
.bg-badge-postre {
  border: 2px solid #795696;
  background-color: #EAD0FF;
}
.bg-badge-postre:hover {
  color: #795696;
}
.badge-active {
  opacity: 1 !important;
}

.bg-badge-type {
  opacity: 1 !important;
  margin-left: 0 !important;
}

.btn-seecontent {
  margin-top: 30px;
  border-radius: 12px;
  color: #482d8d;
  font-size: 16px;
  padding: 0.65rem;
  width: 350px;
  background-color: #FDEDFF;
  display: block;
  text-align: center;
  font-weight: 700;
}
.btn-seecontent-suggeriment {
  margin-top: 30px;
  border-radius: 12px;
  color: #482d8d;
  font-size: 16px;
  padding: 0.65rem;
  background-color: #FDEDFF;
  display: block;
  text-align: center;
  font-weight: 700;
}

.restaurant-content {
  padding: 2rem 0;
}

.badge {
  width: 100%;
  font-size: 14px;
  border-radius: 12px;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.5rem;
  margin: 0.4rem 0;
}

.badge-entrante, .badge-entrante:hover {
  border: 1px solid #482d8d;
  color:#482d8d;
}

.badge-success {
  background-color: #50cd89;
  margin-left: 0 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 14px;
  padding: .3rem .5rem;
  display: block;
  width: 100px;
  text-align: center;
}

.title-tarjeta {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
}
.description-tarjeta {
  font-size: 16px;
  margin-bottom: 26px;
}
.duracion {
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
}

.card-result {
  border: 2px solid #482d8d;
  background-color: white;
  border-radius: 16px;
  padding: 1.25rem 1.25rem 2rem 1.25rem;
}
.category-description {
  font-size: 16px;
}
.img-result {
  height: 125px;
  width: auto !important;
  margin-left: 0;
  margin-bottom: 12px;
}

.bg-recomendacionexperto{
  background-color: white;
  padding: 6px 40px;
  border-radius: 10px;
  color: #482d8d;
  border: 1px solid #482d8d;
}

.truncate-multiline-carousel {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita a 2 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disclaimer {
  color: black;
  font-style: italic;
}

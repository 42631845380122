#carousel_home {
  padding: 0;
  border-radius: 0.625rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-item,
.carousel-inner {
  border-radius: 0.625rem;
}

.carousel-indicators {
  z-index: 0;
}
.carousel-control-prev {
  left: -75px;
}
.carousel-control-next {
  right: -75px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: silver;
  border-radius: 20px;
}

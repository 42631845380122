.ant-dropdown button.event-button {
  &:hover {
    background: white !important;
  }
}

.img-calendar {
  width: 100px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.img-contact {
  width: 300px;
  margin-bottom: 30px;
}

#agenda .card-agenda {
  /* Estilos personalizados para la scrollbar */
  ::-webkit-scrollbar {
    width: 10px; /* Ancho de la scrollbar */
  }

  /* Estilos para el riel de la scrollbar */
  ::-webkit-scrollbar-track {
    background: transparent; /* Hace que el riel sea casi transparente */
  }

  /* Estilos para el pulgar de la scrollbar */
  ::-webkit-scrollbar-thumb {
    background: rgba(
      200,
      200,
      200,
      0.2
    ); /* Hace que el pulgar sea casi transparente */
  }

  /* Hover styles para el pulgar de la scrollbar */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(
      200,
      200,
      200,
      0.5
    ); /* Aumenta ligeramente la opacidad al pasar el mouse sobre el pulgar */
  }
}

.sign-in,
.password-reset {
  .ant-flex {
    height: 100vh !important;
  }
}

.textfield-login {
  color: #482d8d;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 16px;
}

.form-login {
  margin: 5% auto 0 auto;
  padding: 2.5rem 2.5rem 5rem 2.5rem;
}

.banner-login {
  display: none;
}
.container-singin {
  background-color: white;
  padding: 2.5rem;
  flex-direction: column;
  height: 100%;
  display: flex;
}
.logo-login {
  vertical-align: middle;
  height: 125px;
  margin-bottom: 30px;
}

.title-login {
  font-weight: 700;
  margin-bottom: 0.75rem;
  font-size: 22px;
}

.texto-azul-claro,
.texto-azul-claro:hover {
  color: #a4deff;
}

.btn-send-password {
  color: white;
  background-color: #482d8d;
  padding: 0 20.5px;
  height: 44px;
  border: 0;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0.5rem;
}
.btn-send:hover {
  background-color: #614e8f;
}

.btn-cancel-password {
  color: #7e8299;
  background-color: #f9f9f9;
  padding: 0.825rem 1.75rem;
  border: 0;
  box-shadow: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 0.625rem;
  width: 100%;
  text-align: center;
}
.btn-cancel-password:hover {
  color: #7e8299;
  background-color: #f4f4f4;
}

.textarea-login {
  border: 1px solid #482d8d;
  background-color: transparent !important;
  border-radius: 14px;
}

.textfield-login.bg-transparent::placeholder {
  color: #999999;
}

.blue-link {
  color: #313ed2;
  font-size: 14px;
}

.footer_login {
  text-align: right;
  margin-top: 46px;
  margin-bottom: 46px;
  margin-right: 46px;
  position: relative;
  z-index: 1;
}
.error-notice{
  color: red;
  margin-bottom: 25px;
}
.bg_login{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/bg_login.jpg');
  background-size: cover;
  background-position: center;
}

.form-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.form-passwordreset{
  margin: 10% auto 8% auto;
  padding: 2.5rem 2.5rem 5rem 2.5rem;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .left-container-singin {
    flex: 1 auto;
    min-width: 0;
    order: 1;
    width: 50%;
  }
  .right-container-singin {
    flex: 1 auto;
    min-width: 0;
    order: 2;
    width: 50%;
  }
}

@media (min-width: 576px) {
  .d-lg-none {
    display: block;
  }
}

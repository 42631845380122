.MuiPaginationItem-circular.Mui-selected.MuiPaginationItem-page {
  background-color: #7239ea;
  border-radius: 5px;
  color: white;
}

.MuiPaginationItem-circular.Mui-selected.MuiPaginationItem-page:hover {
  background-color: #7239ea;
  border-radius: 5px;
  color: white;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: transparent;
  border-radius: 5px;
  color: #5e6278;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: #e9ecef;
  border-radius: 5px;
  color: #7239ea;
}
